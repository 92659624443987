const Messages = {
  pt: {
    //NAVIGATION
    "nav.comofunciona": "Como funciona",
    "nav.suma": "Sumá",
    "nav.parceiros": "Parceiros",
    //
    //HOME
    //==slider
    "home.slider.title": "Da terra para a nuvem",
    "home.slider.text":
      "A solução online que conecta agricultores familiares e compradores de alimentos",
    "home.slider.button": "Saiba mais",
    //==Info
    "home.info.title": "Como funciona nossa plataforma?",
    "home.info.text":
      "O Sumá atua na capacitação do agricultor familiar, apoiando o seu desenvolvimento e o alinhando às exigências dos compradores regulares de alimentos. Além disso, contribui com informações reais do campo para que o comprador elabore seus cardápios de acordo com os planos de produção locais, e em sintonia com a sazonalidade dos produtos.",
    "home.circle.cadastre": "Cadastre-se",
    "home.circle.agricultor.title": "Você é agricultor familiar?",
    "home.circle.agricultor.text":
      "O Sumá te apóia realizando visitas de campo para avaliação e qualificação para atendimento aos contratos junto a importantes mercados consumidores.",
    "home.circle.comprador.title": "Você é comprador?",
    "home.circle.comprador.text":
      "O Sumá te apóia buscando cooperativas da agricultura familiar para atender suas demandas.",
    "home.circle.entrega.title": "Entrega",
    "home.circle.entrega.text":
      "O Sumá garante a entrega dos produtos frescos direto do campo, com padrão Hortiescolha, em conformidade com todos os requisitos de qualidade de cada cliente.",
    //==Faça parte
    "home.faca.title": "Faça parte do Sumá",
    "home.faca.box1": "Você é um comprador regular de alimentos? {br} Solicite agora seu orçamento",
    "home.faca.box2":
      "Você representa uma cooperativa ou associação de agricultores? {br} Solicite seu cadastro",
    "home.faca.box3": "Você é agricultor familiar? {br} Solicite seu cadastro",
    "home.faca.box4":
      "Entre em contato através do e-mail:  {br} <mail>contato@appsuma.com.br.</mail>",
    //==Historia
    "home.historia.subtitulo": "Sobre o Sumá",
    "home.historia.title": "Uma história de muita dedicação e aprendizado",
    "home.historia.text1":
      "Sumá é o nome da deusa tupi-guarani da agricultura e faz referência a um tempo em que plantar e comer ficavam perto como nesta frase. Em que comida era algo que vinha da terra, não da prateleira. Em que as embalagens eram cascas, não plástico ou papel.",
    "home.historia.text2":
      "O Brasil é um país desigual e a cadeia de distribuição de alimentos contribui para isso. O Sumá propõe uma mudança no sistema ao aproximar quem planta de quem consome alimentos e dessa forma nutrir o sonho de um país unido.",
    //==Premios
    "home.premios.title.support": "Apoios Recebidos",
    "home.premios.title.prizes": "Premios e Reconhecimentos",
    "home.premios.title.investor": "Investidores",
    //
    //FOOTER
    "footer.direitos": "© 2018 Sumá. Todos os direitos reservados.",
    "footer.sc": "Santa Catarina",
    "footer.sc-end": "Avenida Carlos Drummond de Andrade, 507 Balneário Camboriú / SC",
    "footer.sc-email": "contato@appsuma.com.br",
    "footer.sc-tel": "Tel: (47) 2033-9866",
    "footer.sc-whats": "WhatsApp: (47) 98818-7183",
    "footer.rs": "Rio Grande do Sul",
    "footer.rs-end": "Rua Voluntários da Pátria, 684 Pelotas / RS",
    "footer.rs-tel": "Tel: (53) 3025-5786"
  },
  en: {
    //NAVIGATION
    "nav.comofunciona": "How it works",
    "nav.suma": "Sumá",
    "nav.parceiros": "Partners",
    //
    //HOME
    //==slider
    "home.slider.title": "The earth to the cloud",
    "home.slider.text": "The online solution that connects farmers and food buyers",
    "home.slider.button": "Know more",
    //==Info
    "home.info.title": "How our platform?",
    "home.info.text":
      "It also helps with actual field information for the buyer to prepare their menus according to the plans of local production, and in line with the seasonality of products.",
    "home.circle.cadastre": "Register",
    "home.circle.agricultor.title": "You are family farmer?",
    "home.circle.agricultor.text":
      "Suma supports you conducting field visits for evaluation and qualification for service contracts with major consumer markets.",
    "home.circle.comprador.title": "You are buyer?",
    "home.circle.comprador.text":
      "Suma supports you seeking cooperative family farming to meet their demands.",
    "home.circle.entrega.title": "Delivery",
    "home.circle.entrega.text":
      "Suma guarantee delivery of fresh produce straight from the field, with standard Hortiescolha in accordance with all quality requirements of each client.",
    //==Faça parte
    "home.faca.title": "Part of the Sumá",
    "home.faca.box1": "You are a regular buyer of food? {br} Apply now for your budget",
    "home.faca.box2":
      "Do you represent a cooperative or association of farmers?{br} Request Register",
    "home.faca.box3": "You are family farmer?{br} Request Register",
    "home.faca.box4": "Contact by e-mail:  {br} <mail>contato@appsuma.com.br.</mail>",
    //==Historia
    "home.historia.subtitulo": "About Sumá",
    "home.historia.title": "A history of much dedication and learning",
    "home.historia.text1":
      "Where food was something that came from the earth, not the shelf. Where the packages were shells, not plastic or paper.",
    "home.historia.text2":
      "Brazil is an unequal country and the food distribution chain contributes to this. Sumá proposes a change in the system by bringing together those who plant and those who consume food and thus nurturing the dream of a united country.",
    //==Premios
    "home.premios.title.support": "Support Received",
    "home.premios.title.prizes": "Awards and Recognition",
    "home.premios.title.investor": "Investors",
    //
    //FOOTER
    "footer.direitos": "© 2018 SUMA. All rights reserved.",
    "footer.sc": "Santa Catarina",
    "footer.sc-end": "Avenue Carlos Drummond de Andrade, 507 Balneário Camboriú / SC",
    "footer.sc-email": "contato@appsuma.com.br",
    "footer.sc-tel": "Phone: (47) 2033-9866",
    "footer.sc-whats": "WhatsApp: (47) 99638-2288",
    "footer.rs": "Rio Grande do Sul",
    "footer.rs-end": "Street Voluntários da Pátria, 684 Pelotas / RS",
    "footer.rs-tel": "Phone: (53) 3025-5786"
  }
};

export default Messages;
