import React, { useState } from "react";
import jump from "jump.js";
import Slick from "react-slick";
import { FormattedMessage as Intl } from "react-intl";
import SectionPrizes from "./sectionsPrizes";

import oldPrizes from "./data_oldPrizes.json";
import dataSupportList from "./data_support.json";
import dataPrizeList from "./data_prizes.json";
import dataInvestorList from "./data_investors.json";

const Home = () => {
  const [youtubeModal, setYoutubeModal] = useState(false);

  const slickSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <>
      <main className={"home"}>
        <section id={"slider"} className={"home-slider"}>
          <Slick {...slickSettings}>
            <div>
              <div className={"slideItem"}>
                <img
                  src={`${process.env.assetsDomain}/assets/images/slide-1.jpg`}
                  className={"slideItem-backgroundImage"}
                />
                <img
                  src={`${process.env.assetsDomain}/assets/images/bg-slide.png`}
                  className={"slideItem-overlayImage"}
                />
                <div className={"slideItem-gradientOverlay"} />
                <div className={"slideItem-wrapper"}>
                  <h1 className={"slideItem-wrapper-title"}>
                    <Intl id="home.slider.title" />
                  </h1>
                  <p className={"slideItem-wrapper-text"}>
                    <Intl id="home.slider.text" />
                  </p>
                  <span
                    onClick={() => jump("#info", { offset: -150 })}
                    className={"button button-default slideItem-wrapper-button"}
                  >
                    <Intl id="home.slider.button" />
                  </span>
                </div>
              </div>
            </div>
          </Slick>
        </section>
        <section id={"info"} className={"home-info"}>
          <img
            className="home-info-icon"
            src={`${process.env.assetsDomain}/assets/images/sumaLogo.svg`}
            alt=""
          />
          <h2 className={"home-info-title"}>
            <Intl id="home.info.title" />
          </h2>
          <p className={"home-info-text"}>
            <Intl id="home.info.text" />
          </p>
          <div className={"home-info-circle"}>
            <div className={"circleItem"}>
              <img
                className={"circleItem-image"}
                src={`${process.env.assetsDomain}/assets/images/computer.png`}
              />
              <h4 className={"circleItem-title"}>
                <Intl id="home.circle.cadastre" />
              </h4>
            </div>
            <div className={"circleItem"}>
              <img
                className={"circleItem-image"}
                src={`${process.env.assetsDomain}/assets/images/planta.svg`}
              />
              <h4 className={"circleItem-title"}>
                <Intl id="home.circle.agricultor.title" />
              </h4>
              <p className={"circleItem-text"}>
                <Intl id="home.circle.agricultor.text" />
              </p>
            </div>
            <div className={"circleItem"}>
              <img
                className={"circleItem-image"}
                src={`${process.env.assetsDomain}/assets/images/lista.svg`}
              />
              <h4 className={"circleItem-title"}>
                <Intl id="home.circle.comprador.title" />
              </h4>
              <p className={"circleItem-text"}>
                <Intl id="home.circle.comprador.text" />
              </p>
            </div>
            <div className={"circleItem"}>
              <img
                className={"circleItem-image"}
                src={`${process.env.assetsDomain}/assets/images/truck.png`}
              />
              <h4 className={"circleItem-title"}>
                <Intl id="home.circle.entrega.title" />
              </h4>
              <p className={"circleItem-text"}>
                <Intl id="home.circle.entrega.text" />
              </p>
            </div>
          </div>
          <div className={"home-modal"} onClick={() => setYoutubeModal(!youtubeModal)}>
            <img
              className={"home-modal-background"}
              src={`${process.env.assetsDomain}/assets/images/video-background.jpg`}
            />
            <div className={"home-modal-button"}>
              <img
                className={"home-modal-button-icon"}
                src={`${process.env.assetsDomain}/assets/images/playIcon.png`}
              />
              <span className={"home-modal-button-outer"} />
            </div>
          </div>
          <div className={`youtubePlayer ${youtubeModal ? "opened" : ""}`}>
            <iframe
              src="https://www.youtube.com/embed/lwhlPCP-5Ng"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
            <span
              onClick={() => setYoutubeModal(!youtubeModal)}
              className={"youtubePlayer-background"}
            />
          </div>
        </section>
        <section id={"participe"} className={"home-participe"}>
          <h2 className={"home-participe-title"}>
            <Intl id="home.faca.title" />
          </h2>
          <img
            className={"home-participe-image"}
            src={`${process.env.assetsDomain}/assets/images/agricultorasuma.png`}
            alt=""
          />
          <div className={"home-participe-wrapper"}>
            <p className={"home-participe-textBox blue"}>
              <Intl id="home.faca.box1" values={{ br: <br /> }} />
            </p>
            <p className={"home-participe-textBox orange"}>
              <Intl id="home.faca.box2" values={{ br: <br /> }} />
            </p>
            <p className={"home-participe-textBox orange"}>
              <Intl id="home.faca.box3" values={{ br: <br /> }} />
            </p>
            <p className={"home-participe-textBox blue"}>
              <Intl
                id="home.faca.box4"
                values={{
                  mail: (...chunk) => <a href="mailto:contato@appsuma.com.br">{chunk}</a>,
                  br: <br />
                }}
              />
            </p>
          </div>
        </section>
        <section
          id={"history"}
          className={"home-history"}
          style={{
            backgroundImage: `url(${process.env.assetsDomain}/assets/images/overlay-suma.png)`
          }}
        >
          <div>
            <small className={"home-history-subtitle"}>
              <Intl id="home.historia.subtitulo" />
            </small>
            <h2 className={"home-history-title"}>
              <Intl id="home.historia.title" />
            </h2>
            <p>
              <Intl id="home.historia.text1" />
            </p>
            <p>
              <Intl id="home.historia.text2" />
            </p>
          </div>
          <div>
            <ul className={"home-history-faces"}>
              <li>
                <img src={`${process.env.assetsDomain}/assets/images/pessoa1.webp`} alt="" />
              </li>
              <li>
                <img src={`${process.env.assetsDomain}/assets/images/pessoa2.webp`} alt="" />
              </li>
              <li>
                <img src={`${process.env.assetsDomain}/assets/images/pessoa3.webp`} alt="" />
              </li>
              <li>
                <img src={`${process.env.assetsDomain}/assets/images/pessoa4.webp`} alt="" />
              </li>
            </ul>
          </div>
        </section>
        <SectionPrizes
          id="apoio-suporte"
          intlText="home.premios.title.support"
          prizeList={dataSupportList}
        />
        <SectionPrizes
          id="premios-recebidos"
          intlText="home.premios.title.prizes"
          prizeList={dataPrizeList}
        />
        <SectionPrizes
          id="investidores"
          intlText="home.premios.title.investor"
          prizeList={dataInvestorList}
        />
      </main>
    </>
  );
};

export default Home;
