export default (state, dispatch) => {
  const changeLanguage = async language => {
    if (state.language === language) return;

    localStorage.setItem("language", language);
    dispatch({ type: "SWITCH_LANGUAGE", payload: language });
  };

  return {
    changeLanguage
  };
};
