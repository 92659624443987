import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage as Intl } from "react-intl";

const Footer = () => {
  return (
    <footer className={"footer"}>
      <section className={"footer-section logo"}>
        <img
          className="footer-logo"
          src={`${process.env.assetsDomain}/assets/images/sumaLogo_white.svg`}
          alt=""
        />

        <div className={"footer-socialMidia"}>
          <a href={"https://www.facebook.com/sumapptecnologia/"} target={"_blank"}>
            <img
              className="footer-socialMidia-item"
              src={`${process.env.assetsDomain}/assets/images/social/facebook.svg`}
              alt=""
            />
          </a>
          <a href={"https://www.linkedin.com/company/sum%C3%A1"} target={"_blank"}>
            <img
              className="footer-socialMidia-item"
              src={`${process.env.assetsDomain}/assets/images/social/linkedin.svg`}
              alt=""
            />{" "}
          </a>
          <a href={"https://www.instagram.com/sumapptecnologia/"} target={"_blank"}>
            <img
              className="footer-socialMidia-item"
              src={`${process.env.assetsDomain}/assets/images/social/instagram.svg`}
              alt=""
            />{" "}
          </a>
        </div>
        <small>
          <Intl id="footer.direitos" />
        </small>
      </section>
      <section className={"footer-section santa"}>
        <strong>
          <Intl id="footer.sc" />
        </strong>
        <p>
          <Intl id="footer.sc-end" />
        </p>
        <a href={"mailto:contato@appsuma.com.br"}>
          <Intl id="footer.sc-email" />
        </a>
        <a href={"tel:+5547996382288"}>
          <Intl id="footer.sc-tel" />
        </a>
        <a href={"https://api.whatsapp.com/send?phone=5547988187183"}>
          <Intl id="footer.sc-whats" />
        </a>
      </section>
    </footer>
  );
};

export default Footer;
