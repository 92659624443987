import React from "react";

import application from "./application";

const contexts = {
  application
};

const BucketProvider = props => {
  const contextArray = Object.values(contexts);
  return processProviders(contextArray, props);
};

const processProviders = (contexts, props) => {
  if (contexts.length) {
    const { Provider } = contexts.shift();
    return <Provider>{processProviders(contexts, props)}</Provider>;
  } else {
    return props.children;
  }
};

const getContext = context => {
  return contexts[context].Context;
};

const getActions = context => {
  return contexts[context].actions();
};

export { BucketProvider, getContext, getActions };
