import React, { useState, useEffect } from "react";
import jump from "jump.js";
import { getContext, getActions } from "@html-context";
import { FormattedMessage as Intl } from "react-intl";

const NavBar = () => {
  const appContext = getContext("application");
  const { language } = React.useContext(appContext);

  const [isTop, setIsTop] = useState(true);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    let windowWidth = window.screen.width;

    window.addEventListener("resize", e => {
      windowWidth = window.screen.width;
      if (window.screen.width >= 1024) setToggle(false);
    });

    window.addEventListener("scroll", e => {
      console.log(windowWidth);
      if (window.scrollY >= 200 && windowWidth > 1024) return setIsTop(false);
      if (window.scrollY < 200 && windowWidth > 1024) return setIsTop(true);
    });

    return () => {
      window.removeEventListener("resize");
      window.removeEventListener("scroll");
    };
  }, []);

  return (
    <nav className={`navBar ${!isTop ? "fixed" : ""}`}>
      <img
        className="navBar-logo"
        src={`${process.env.assetsDomain}/assets/images/${
          isTop ? "sumaLogo_white.svg" : "sumaLogo.svg"
        }`}
        alt=""
      />

      <ul className={`navBar-list ${toggle ? "opened" : ""}`}>
        <li
          className={`navBar-list-hamburguer ${toggle ? "opened" : ""}`}
          onClick={() => setToggle(!toggle)}
        />
        <JumpItem linkTo={"#info"}>
          <Intl id={"nav.comofunciona"} />
        </JumpItem>
        <JumpItem linkTo={"#participe"}>
          <Intl id={"nav.suma"} />
        </JumpItem>
        <JumpItem linkTo={"#premios"}>
          <Intl id={"nav.parceiros"} />
        </JumpItem>
        <li className={"navBar-buttons"}>
          <button
            onClick={() => getActions("application").changeLanguage("en")}
            className={`navBar-buttons-item ${language === "en" ? "active" : ""}`}
          >
            En
          </button>
          <button
            onClick={() => getActions("application").changeLanguage("pt")}
            className={`navBar-buttons-item ${language === "pt" ? "active" : ""}`}
          >
            Pt
          </button>
        </li>
      </ul>
    </nav>
  );
};

const JumpItem = ({ children, linkTo }) => (
  <li onClick={() => jump(linkTo, { offset: -150 })} className={"navBar-list-item"}>
    {children}
  </li>
);

export default NavBar;
