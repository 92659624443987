import React from "react";
import { FormattedMessage as Intl } from "react-intl";

const SectionPrizes = ({ id, prizeList, intlText }) => {
  return (
    <section id={id} className={"home-premios"}>
      <img
        className={"home-premios-icon"}
        src={`${process.env.assetsDomain}/assets/images/premio.png`}
        alt=""
      />
      <h3 className={"home-premios-title"}>
        <Intl id={intlText} />
      </h3>
      <ul className={"home-premios-list"}>
        {prizeList.map((prize, index) => (
          <PrizeItem
            key={index + prize.link}
            name={prize.name}
            link={prize.link}
            assetPath={prize.assetPath}
          />
        ))}
      </ul>
    </section>
  );
};

const PrizeItem = ({ name = "", link, assetPath }) => (
  <li>
    <a href={link} target={"_blank"}>
      <img src={`${process.env.assetsDomain}${assetPath}`} alt={name} />
    </a>
  </li>
);

export default SectionPrizes;
