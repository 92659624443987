import React, { useEffect } from "react";
import { IntlProvider } from "react-intl";
import { getContext, getActions } from "@html-context";
import Messages from "../localization";

import BaseHelmet from "../components/Helmet";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import Home from "./Home";

export default props => {
  const appContext = getContext("application");
  const { language } = React.useContext(appContext);

  useEffect(() => {
    const lang = localStorage.getItem("language");
    if (lang) {
      getActions("application").changeLanguage(lang);
    }
  }, []);

  return (
    <React.Fragment>
      <IntlProvider locale={language} messages={Messages[language]}>
        <BaseHelmet />
        <NavBar />
        <Home />
        <Footer />
      </IntlProvider>
    </React.Fragment>
  );
};
