export default (state, action) => {
  console.log("%c CONTENT REDUCER", "color: green");

  switch (action.type) {
    case "SWITCH_LANGUAGE":
      return { ...state, language: action.payload };

    default:
      return state;
  }
};
