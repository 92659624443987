import React, { useEffect } from "react";
import { Route, useLocation } from "react-router-dom";

const ScrollTopRoute = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(
    () => {
      window.scrollTo(0, 0);
    },
    [pathname]
  );
  return <>{children}</>;
};

export default ScrollTopRoute;
