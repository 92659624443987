import React from "react";
import { BucketProvider } from "./contexts";
import ScrollToTop from "./components/ScrollToTop";

import Index from "./views/index.react";

export default () => {
  return (
    <BucketProvider>
      <ScrollToTop>
        <Index />
      </ScrollToTop>
    </BucketProvider>
  );
};
