import React from "react";
import Helmet from "react-helmet";

const BaseHelmet = () => {
  return (
    <Helmet>
      <title>Sumá</title>
      <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <script src="https://oss.maxcdn.com/libs/html5shiv/3.7.2/html5shiv.js" />
      <script src="https://oss.maxcdn.com/libs/respond.js/1.4.2/respond.min.js" />

      <link
        rel="stylesheet"
        type="text/css"
        charset="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />

      <link href={`${process.env.assetsDomain}/assets/css/main.css`} rel="stylesheet" />

      <link rel="manifest" href="/manifest.json" />
    </Helmet>
  );
};

export default BaseHelmet;
